import { RouteObject, type LoaderFunction } from "react-router-dom";
import getCustomerDevices from "../../../../API/treescout";
import TreescoutPage from "./TreescoutPage";
import TreescoutCrumb from "./TreescoutCrumb";

const routes: RouteObject = {
  Component: TreescoutPage,
  path: "treescout",
  loader: getCustomerDevices as LoaderFunction,
  handle: {
    Crumb: TreescoutCrumb,
  },
  shouldRevalidate: () => false,
};

export default routes;
