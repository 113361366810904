import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./BlossomLegend.module.css";

export type BlossomLegendProps = {
  maxCount: number;
  minCount: number;
};

export default function BlossomClusterCountLegend({
  maxCount,
  minCount,
}: BlossomLegendProps): React.ReactNode {
  const { t } = useTranslation();
  return (
    <aside className={`${styles.blossomLegend} boxed`}>
      <h1 className={styles.heading}>{t("heading.Blossom count")}</h1>

      <Box className={styles.gradientBox}></Box>

      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <p> {minCount}</p>
        </Grid>
        <Grid item container xs={6} alignItems="flex-end" direction="column">
          <Grid item>
            <p>{maxCount}</p>
          </Grid>
        </Grid>
      </Grid>
    </aside>
  );
}
