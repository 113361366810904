import { useAuth } from "./auth";
import { LoaderFunctionArgs, Params } from "react-router-dom";
import { getUrl } from "./AureaApi";

type GetCustomerDevicesParams = LoaderFunctionArgs & {
  params: Params<"customerId">;
};

type TreescoutDevice = {
  id: string;
  reseller_id: number;
  customer_id: number;
  previous_customer_ids: number[];
  sending_messages: string;
  serial_number: string;
  health_report: HealthReport | ErrorHealthReport;
  created_at: string;
  updated_at: string;
};

export type ErrorHealthReport = {
  code: string;
  detail: null;
  message: string;
};

export type HealthReport = {
  gps: {
    connected: boolean;
    fix_type: string;
  };
  gps_offsets: {
    front_back: number;
    left_right: number;
    up_down: number;
  };
  network: {
    cell: {
      signal_strength: number;
      status: string;
    };
    wifi: {
      signal_strength: number;
      status: string;
    };
  };
  software_versions: {
    aurea_software_version: string;
    ui_version: string;
  };
};

export type TreescoutDeviceList = TreescoutDevice[];

export default async function getCustomerDevices({
  params: { customerId },
}: GetCustomerDevicesParams): Promise<null | TreescoutDeviceList> {
  const token = await useAuth.getState().getToken();
  if (!token) {
    return null;
  }

  const treescoutDevices = await fetch(
    getUrl(`/v1/customer/${customerId}/tms_installation`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const devices = await treescoutDevices.json();

  const devicesWithHealth = await Promise.all(
    devices.map(async (device: TreescoutDevice) => {
      const healthReport = await fetch(
        getUrl(`/v1/tms_installation/${device.id}/health_report/latest`),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const healthData = await healthReport.json();

      return {
        ...device,
        health_report: healthData,
      };
    }),
  );

  return devicesWithHealth;
}
