import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TreeLegend.module.css";

export default function TreeLegend(): React.ReactNode {
  const { t } = useTranslation();

  return (
    <aside className={`${styles.treeLegend} boxed`}>
      <h1 className={styles.heading}>{t("heading.Trees")}</h1>

      <div className={styles.treeLegendItem}>
        <svg className={styles.treeStyleIcon} viewBox="0 0 24 24">
          <circle className={styles.tree} cx="12" cy="12" r="12" />
        </svg>
        {t("legend-key.Trees")}
      </div>
      <div className={styles.treeLegendItem}>
        <svg className={styles.treeStyleIcon} viewBox="0 0 24 24">
          <circle className={styles.photo} cx="12" cy="12" r="12" />
        </svg>
        {t("legend-key.Tree photo")}
      </div>
    </aside>
  );
}
