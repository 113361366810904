import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import React from "react";
import { Layer, Source } from "react-map-gl/maplibre";
import { Outlet } from "react-router-dom";
import MapControl from "../MapControl";
import useLayerEvent from "../useLayerEvent";
import VigourLegend from "./VigourLegend";
import { CutoffRange } from "../../../routes/customer/_customer_id/taskmap/Taskmap";
import Popup from "../Popup";

export type VigourFeatureProperties = {
  vigour_class: number | null;
  vegetation_height_m: number | null;
  plot_id: number;
  [other: string]: unknown;
};
export type VigourFeatureCollection = FeatureCollection<
  Polygon | MultiPolygon,
  VigourFeatureProperties
>;

export type VigourLayerProps = {
  geojson: VigourFeatureCollection;
  cutoff?: CutoffRange;
  onHover?: (feature: VigourFeatureProperties | null) => void;
};

function defaultHover() {
  // do nothing
}

export default function VigourLayer({
  geojson,
  cutoff,
  onHover = defaultHover,
}: VigourLayerProps): React.ReactNode {
  useLayerEvent(
    "mouseenter",
    "vigour-fill",
    /* istanbul ignore next */ (event) => {
      if (event.features?.length) {
        onHover(event.features[0].properties as VigourFeatureProperties);
      }
    },
  );
  const rootStyle = getComputedStyle(document.documentElement);
  return (
    <>
      <Source id="vigour" type="geojson" data={geojson}>
        <Outlet />
        <Layer
          type="fill"
          id="vigour-fill"
          beforeId="result"
          filter={[
            "all",
            ["!=", ["get", "vigour_class"], 0],
            [">=", ["get", "vigour_class"], cutoff ? cutoff.min : 0],
            ["<=", ["get", "vigour_class"], cutoff ? cutoff.max : 7],
          ]}
          paint={{
            "fill-color": [
              "interpolate",
              ["linear"],
              ["number", ["get", "vigour_class"]],
              0,
              rootStyle.getPropertyValue("--color-vigour-map-0"), // dark red
              1,
              rootStyle.getPropertyValue("--color-vigour-map-1"), // dark orange
              2,
              rootStyle.getPropertyValue("--color-vigour-map-2"), // orange
              3,
              rootStyle.getPropertyValue("--color-vigour-map-3"), // yellow
              4,
              rootStyle.getPropertyValue("--color-vigour-map-4"), // lime green
              5,
              rootStyle.getPropertyValue("--color-vigour-map-5"), // light green
              6,
              rootStyle.getPropertyValue("--color-vigour-map-6"), // grass green
              7,
              rootStyle.getPropertyValue("--color-vigour-map-7"), // dark green
            ],
            "fill-opacity": 0.7,
          }}
        />
        <Popup layer="vigour-fill" component={PopupInformation} />
      </Source>
      <MapControl position="bottom-left">
        <VigourLegend />
      </MapControl>
    </>
  );
}

function PopupInformation({
  properties,
}: {
  properties: VigourFeatureProperties;
}) {
  if (!properties.vigour_class) {
    return null;
  }

  return <strong>{properties.vigour_class?.toFixed(0)}</strong>;
}
