import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function NoTreeSnackbar(): React.ReactNode {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbar] = useState(true);
  const closeSnackbar = () => setSnackbar(false);
  return (
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        sx={{ width: "100%" }}
        severity="info"
        action={
          <IconButton
            color="inherit"
            size="small"
            href=""
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {t("message.The tree positions are being processed")}
      </Alert>
    </Snackbar>
  );
}
