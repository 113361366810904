import React from "react";
import { useTranslation } from "react-i18next";
import {
  TreescoutDeviceList,
  ErrorHealthReport,
  HealthReport,
} from "../../../../API/treescout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./Treescout.module.css";

interface TreescoutProps {
  treescoutDevices: TreescoutDeviceList;
}

export default function Treescout({
  treescoutDevices,
}: TreescoutProps): React.ReactNode {
  const { t } = useTranslation();

  const isErrorReport = (
    report: HealthReport | ErrorHealthReport,
  ): report is ErrorHealthReport => {
    return "code" in report;
  };

  return (
    <div className={styles.container}>
      {treescoutDevices.map((device) => (
        <Accordion key={device.id} className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={styles.summary}
            sx={{
              backgroundColor: "#f5f5f5",
              "&:hover": {
                backgroundColor: "#eeeeee",
              },
            }}
          >
            <div className={styles.headerContent}>
              <Typography variant="subtitle1">
                Device {device.serial_number}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color:
                    device.sending_messages === "ONLINE"
                      ? "#2e7d32"
                      : "#d32f2f",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  backgroundColor:
                    device.sending_messages === "ONLINE"
                      ? "#e8f5e9"
                      : "#ffebee",
                }}
              >
                {device.sending_messages}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.sectionTitle}>
                  {t("heading.basic info")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography>{`${t("label.customer id")}: ${device.customer_id}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{`${t("label.reseller id")}: ${device.reseller_id}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{`${t("label.previous customer ids")}: ${device.previous_customer_ids.join(", ")}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {isErrorReport(device.health_report) ? (
                  <Typography
                    sx={{
                      backgroundColor: "#ffebee",
                      padding: 2,
                      borderRadius: 1,
                      color: "#c62828",
                    }}
                  >
                    {device.health_report.message}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h6" className={styles.sectionTitle}>
                      {t("heading.gps information")}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.gps connected")}: ${device.health_report.gps.connected}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.gps fix type")}: ${device.health_report.gps.fix_type}`}</Typography>
                      </Grid>
                    </Grid>

                    <Typography variant="h6" className={styles.sectionTitle}>
                      {t("heading.gps offsets")}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.gps offsets front back")}: ${device.health_report.gps_offsets.front_back}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.gps offsets left right")}: ${device.health_report.gps_offsets.left_right}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.gps offsets up down")}: ${device.health_report.gps_offsets.up_down}`}</Typography>
                      </Grid>
                    </Grid>

                    <Typography variant="h6" className={styles.sectionTitle}>
                      {t("heading.network status")}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.cell signal strength")}: ${Math.round(device.health_report.network.cell.signal_strength)}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.cell status")}: ${device.health_report.network.cell.status}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.wifi signal strength")}: ${Math.round(device.health_report.network.wifi.signal_strength)}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.wifi status")}: ${device.health_report.network.wifi.status}`}</Typography>
                      </Grid>
                    </Grid>

                    <Typography variant="h6" className={styles.sectionTitle}>
                      {t("heading.software versions")}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.aurea version")}: ${device.health_report.software_versions.aurea_software_version}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>{`${t("label.ui version")}: ${device.health_report.software_versions.ui_version}`}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      {treescoutDevices.length === 0 && (
        <p className={styles.noDevice}>{t("label.No devices found")}</p>
      )}
    </div>
  );
}
